<template>
  <div class="home-page fill-height">

    <v-row class="fill-height" style="margin-top: 0 !important;">
      <left-side-image/>

      <v-col cols="12" md="7" >

        <v-row align="center" justify="center">
          <v-col cols="12" md="8">
            <div class="mt-12 black--text">
              <h1 class="text-center today-regular home-title">INGRESAR INFORMACIÓN</h1>
            </div>
            <p class="primary--text today-medium text-center" style="">Para iniciar el proceso de solicitud de tarjeta ingresa el
              <br> número de tu documento</p>
          </v-col>
        </v-row>

        <v-col class="d-flex align-center">
          <v-container>

            <v-row align="center" justify="center" class="mt-2">
              <v-col cols="12" md="6" lg="8">

                <div class="px-14 pb-6">
                  <v-form
                      ref="form"
                      lazy-validation
                      @submit.prevent="singUp">

                    <v-select
                        v-model="document_type"
                        :items="document_types"
                        item-text='value'
                        item-value='key'
                        :rules="[v => !!v || 'Selecciona el campo']"
                        label="Tipo de documento"
                        required
                        outlined
                        dense
                        color="primary"
                        class="select-field"
                    ></v-select>

                    <v-text-field
                        v-model="document_id"
                        :rules="[v => !!v || 'Ingresa el documento']"
                        type="text"
                        label="Número de documento"
                        required
                        outlined
                        dense
                        color="primary"
                        class="input-field"
                        @keyup="cleanDocument"
                    ></v-text-field>

                    <a href="https://app-biocredit.s3.amazonaws.com/web_app/coopcentral/terms/PoliticaTratamientoDatos.docx.pdf" target="_blank" class="terms-link mt-2 today-bold" style="font-size: 0.75rem !important;" color="primary">Al ingresar estás aceptando los términos y condiciones, políticas de privacidad y tratamiento de datos personales</a>

                    <v-layout justify-center pt-4 mt-6>
                      <v-card-actions>
                        <v-btn
                            :loading="loading"
                            :disabled="loading"
                            @click="singUp"
                            color="primary"
                            type="submit"
                            class="px-12 text-capitalize vardi-button">
                          Continuar
                        </v-btn>
                      </v-card-actions>
                    </v-layout>

                  </v-form>
                </div>


              </v-col>
            </v-row>

          </v-container>

        </v-col>

      </v-col>
    </v-row>

    <v-snackbar
        v-model="snackbar"
        :timeout="timeout"
        bottom
        right>
      <div v-html="snackbarMessage"></div>
    </v-snackbar>
  </div>

</template>

<script>
import OnboardingModal from "@/components/Layouts/OnboardingModal";
import LeftSideImage from "@/components/Layouts/LeftSideImage";
import {api} from "@/helpers/axios";
import {constants} from "@/helpers/constants";
import {locals} from "@/helpers/locals";


export default {
  name: 'home-app',
  components: {OnboardingModal, LeftSideImage},
  data() {
    return {
      document_type: '',
      document_id: '',
      coupon: '',
      dialog: false,
      promoCode: false,
      loading: false,
      settings: null,
      document_types: [],
      snackbar: false,
      timeout: 3000,
      snackbarMessage: '',
      user: null

    }
  },
  methods: {
    cleanDocument() {
      this.document_id = this.document_id.replace(/[^0-9]/gi, '');
      //console.log(this.document_id, this.document_id.replace(/[^0-9]/gi, ''))
    },
    singUp() {
      let validate = this.$refs.form.validate();
      if(validate) {
        this.loading = true;

        api.post(constants.endPoints.isRegistered, {
          document_type: this.document_type,
          document_id: this.document_id
        }).then(function (response) {

              this.loading = false;
              let exist_user = response.data.data.exist_user;

              let user = {
                document_type: this.document_type,
                document_id: this.document_id,
              };

              locals.setLocal('user', JSON.stringify(user));

              if(exist_user) {
                user.login = true;
                locals.setLocal('user', JSON.stringify(user));

                this.$router.push('/tomar-selfie');
                //this.$router.push('/preparate-para-selfie');
              } else {
                //this.$router.push('/registro-de-documento');
                this.$router.push('/tomar-selfie');
              }
            }.bind(this))
            .catch(function (error) {
              let errors = error.response.data.errors;
              let message = '';
              for (var [key, value] of Object.entries(errors)) {
                message = message + value + '<br>';
              }
              this.snackbarMessage = message;
              this.snackbar = true;
              this.loading = false;

            }.bind(this))
      }

    }
  },
  mounted() {
    this.settings = locals.getLocal('settings');
  },
  created() {
    api.get(constants.endPoints.settings)
        .then(response => {
          locals.setLocal('settings', JSON.stringify(response.data.data));
          this.settings = locals.getLocal('settings');
          this.settings.config.forEach((value) => {
            if(value.key == 'document_types') {
              this.document_types = value.value;
            }
          });
        })
    this.user = locals.getLocal('user');

  },
}
</script>

<style>
.v-application .text-caption {
  letter-spacing: 0 !important;
  line-height: 0.8rem !important;
  font-size: 0.65rem !important;
}

.terms-link {
  display: block;
}

.v-card__title {
  word-break: inherit;
}

.home-title {
  font-size: 2.5em;
  line-height: 1;
}

.card-subtitle {
  font-size: 22px;
  line-height: 1;
}

.home-image {
  height: 100px;
}

@media (max-width: 600px) {
  .home-title {
    font-size: 2em;
    line-height: 1;
  }

  .card-subtitle {
    font-size: 18px;
    line-height: 1;
  }

  .home-image {
    height: 140px;
  }
}

input[type=number]::-webkit-outer-spin-button,
input[type=number]::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
  appearance: none;
  margin: 0;
}

</style>

