import {api} from "@/helpers/axios";
import {locals} from "@/helpers/locals";

export const constants = {

    //baseUrl: '' + process.env.URL,
    baseUrl: 'https://coopcentral-dev-tc.bio.credit/api',
    //baseUrl: 'https://coopcentral-prod-tc.bio.credit/api',
    //baseUrl: 'http://localhost:8000/api',

    endPoints: {
        settings: '/settings',
        storeContact: '/store-contact',
        isRegistered: '/is-registered',
        paymentRequest: '/pay',
        validateDocuments: '/validate-documents',
        registerUser: '/register-user',
        login: '/login',
        logout: '/logout',
        user: '/user',
        form: '/form',
        validateFullForm: '/validate-full-form',
        validateFullFormData: '/validate-full-form-data',
        restrictionForm: '/restriction-form',
        saveForm: '/save-field-value',
        cards: '/cards',
        selectCard: '/select-card',
        policies: '/insurance-policies',
        simulator: '/simulator',
        phoneIsRegistered: '/phone-is-registered',
        registerPhone: '/register-phone',
        validatePhone: '/validate-phone',
        createRequest: '/create-request',
        signDocuments: '/sign-documents',
        signDocument: '/sign-document',
        reSendSignatureOtp: '/re-send-signature-otp',
        startPayment: '/start-payment',
        restrictionsValidation: '/restrictions-validation',
        updateExpeditionDate: '/update-expedition-date',
        endRequest: '/end-request',
        // Evidente Services
        validateServiceEvidente: '/validate-service',
        initializeOtpServiceEvidente: '/initialize-otp-service',
        generateOtpCodeServiceEvidente: '/generate-otp-code-service',
        questionsServiceEvidente: '/questions-service',
        answersServiceEvidente: '/answers-service',
        verifyOtpServiceEvidente: '/verify-otp-service',
        requestHigherQuota: '/request-higher-quota',
        // ADO
        adoValidation: '/ado-validation',
        adoFinish: '/ado-finish'
    },

    paymentDays: [
        {
            minDay: 1,
            maxDay: 7,
            day: 4
        },
        {
            minDay: 8,
            maxDay: 14,
            day: 8
        },
        {
            minDay: 15,
            maxDay: 21,
            day: 15
        },
        {
            minDay: 22,
            maxDay: 31,
            day: 22
        }
    ],
};

export const services = {
    logout
};

function logout() {

    api.get(constants.endPoints.logout, true)
        .then(response => {
            console.log(response);
            locals.clearLocal();
        })

}
