<template>
  <home-app />
</template>

<script>
  import HomeApp from '../components/HomeApp'

  export default {
    name: 'Home',
    components: {
      HomeApp
    },
  }
</script>
