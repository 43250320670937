import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },
  {
    path: '/simular-financiacion',
    name: 'SimulateFinancing',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "simular-financiacion" */ '../views/Simulate/SimulateFinancing.vue')
  },
  {
    path: '/datos-de-contacto',
    name: 'ContactData',
    component: () => import(/* webpackChunkName: "datos-de-contacto" */ '../views/ContactData/ContactData.vue')
  },
  {
    path: '/registro-de-documento',
    name: 'RegisterDocument',
    component: () => import(/* webpackChunkName: "registro-de-documento" */ '../views/Register/RegisterDocument.vue')
  },
  {
    path: '/subir-documento',
    name: 'UploadDocument',
    component: () => import(/* webpackChunkName: "subir-documento" */ '../views/Register/UploadDocument.vue')
  },
  {
    path: '/preparate-para-selfie',
    name: 'PrepareSelfie',
    component: () => import(/* webpackChunkName: "preparate-para-selfie" */ '../views/Register/PrepareSelfie.vue')
  },
  {
    path: '/tomar-selfie',
    name: 'TakeSelfie',
    component: () => import(/* webpackChunkName: "tomar-selfie" */ '../views/Register/TakeSelfie.vue')
  },
  {
    path: '/polizas',
    name: 'Policy',
    component: () => import(/* webpackChunkName: "polizas" */ '../views/Steps/Policy.vue')
  },
  {
    path: '/simulador',
    name: 'Simulator',
    component: () => import(/* webpackChunkName: "simulador" */ '../views/Steps/Simulator.vue')
  },
  {
    path: '/formulario',
    name: 'Form',
    component: () => import(/* webpackChunkName: "formulario" */ '../views/Steps/Form.vue')
  },
  {
    path: '/validar-perfil',
    name: 'ValidateProfile',
    component: () => import(/* webpackChunkName: "formulario" */ '../views/Steps/ValidateProfile.vue')
  },
  {
    path: '/tipo-de-tarjeta',
    name: 'CardType',
    component: () => import(/* webpackChunkName: "formulario" */ '../views/Steps/CardType.vue')
  },
  {
    path: '/firma-documentos',
    name: 'SignatureDocuments',
    component: () => import(/* webpackChunkName: "firma-documentos" */ '../views/Steps/SignatureDocuments.vue')
  },
  {
    path: '/finalizar-solicitud',
    name: 'FinishRequest',
    component: () => import(/* webpackChunkName: "firma-documentos" */ '../views/Steps/FinishRequest.vue')
  },
  {
    path: '/firma-pagare',
    name: 'SignaturePayment',
    component: () => import(/* webpackChunkName: "firma-parage" */ '../views/Steps/SignaturePayment.vue')
  },
  {
    path: '/pago',
    name: 'Payment',
    component: () => import(/* webpackChunkName: "pago" */ '../views/Steps/Payment.vue')
  },
  {
    path: '/respuesta-pago/:status/:request_id',
    name: 'PaymentResponse',
    component: () => import(/* webpackChunkName: "respuesta-pago" */ '../views/Steps/PaymentResponse/PaymentResponse.vue')
  },
  {
    path: '/dev',
    name: 'DevPage',
    component: () => import(/* webpackChunkName: "dev" */ '../views/Dev/DevPage.vue')
  },
  {
    path: '*',
    component: () => import(/* webpackChunkName: "dev" */ '../views/NotFound.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
