export const locals = {
    getLocal,
    setLocal,
    clearLocal,
    getImageFront,
    setImageFront,
    getImageBack,
    setImageBack
};

function getLocal(item) {
    let localVariable = JSON.parse(localStorage.getItem(item));
    return localVariable;
}

function setLocal(item, data) {
    localStorage.setItem(item, data);
}

function clearLocal() {
    localStorage.clear();
}

let imageFront = null;
let imageBack = null;

function setImageFront(image) {
    imageFront = image;
}

function getImageFront() {
    return imageFront;
}

function setImageBack(image) {
    imageBack = image;
}

function getImageBack() {
    return imageBack;
}
